<template>
  <div
    v-if="item"
    class="shop-product-card-image"
    :class="{ 'is-product': item.type === 'product' }"
  >
    <AtomImage
      v-if="item.image"
      :src="item.image"
    />

    <div
      v-if="item.brand?.logo || item.manufacturer?.logo"
      class="shop-product-card-image__brand"
    >
      <AtomImage :src="item.brand?.logo || item.manufacturer?.logo" />
    </div>

    <ShopProductCardDiscount v-if="item.discount?.price" />
    <ShopProductCardLocation
      v-if="item.location?.name"
    />
    <ShopProductCardSpeaker v-if="item.speaker?.name && !item.location?.name" />
  </div>
</template>

<script setup>
const item = inject('item');
</script>

<style lang="scss">
.shop-product-card-image {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    aspect-ratio: 2.09;
    background: $C_WHITE;

    &.is-product {
        padding: 30px;

        .atom-image {
            img {
                object-fit: contain;
            }
        }
    }
}

.shop-product-card-image__brand {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 89px;
    height: 53px;
}

.shop-product-card-discount__flag {
    @include typo-font('regular');

    position: absolute;
    right: 20px;
    bottom: 20px;
    width: auto;
    padding: 5px 10px;
    border-radius: 3px;
    background: $C_RED;
    color: $C_WHITE;
    font-size: 14px;
    pointer-events: none;
}
</style>
